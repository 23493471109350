export * from "./cache";
export * from "./loaders";

export const routes = {
  home: "/",
  products: "/products",
  kitchens: "/kitchens",
  appliances: "/appliances",
  doors: "/parts/doors",
  handles: "/parts/handles",
  cabinets: "/parts/cabinets",
  worktops: "/parts/worktops",
  laminates: "/parts/laminates",
  sinks: "/parts/sinks",
  taps: "/parts/taps",
  shelving: "/parts/shelving",
  splashbacks: "/parts/splashbacks",
  about: "/about",
  history: "/history",
  innovation: "/innovation-and-premisess",
  mission: "/mission-and-values",
  sustainability: "/sustainability",
  sustainabilityBook: "/little-book-of-sustainability",
  accreditations: "/accreditations",
  corporateResponsibility: "/committed-to-excellence",
  terms: "/terms",
  marketSectors: "/market-sectors",
  privateDevelopers: "/house-builders",
  bespokeSolutions: "/high-rise-developers",
  socialHousing: "/social-housing-providers",
  retailCustomers: "/retail-customers",
  news: "/news",
  newsLatestTrends: "/news/latest-trends",
  newsCaseStudies: "/news/case-studies",
  newsCompany: "/news/company",
  newsSustainability: "/news/sustainability",
  newsKitchenLife: "/news/kitchen-life",
  newsAuthor: "/news/authors",
  workingWithUs: "/working-with-us",
  careers: "/working-with-us#available-positions",
  ourTeam: "/our-team",
  brochures: "/brochures",
  contact: "/contact",
  contactCustomerCare: "/contact/customer-experience",
  contactAftersales: "/contact/buying-direct",
  customerReviews: "/customer-reviews",
  visualiser: "/kitchen-visualiser",
  extraParts: "/order-extras",
  instagram: "https://www.instagram.com/paularosa_kitchens/",
  linkedIn: "https://linkedin.in/company/manhattan",
  facebook:
    "https://facebook.com/pages/biz/local/Paula-Rosa-Manhattan/1549451065310078",
  trustpilot: "https://uk.trustpilot.com/review/prmf.co.uk",
};

const dict = {
  [routes.products]: {
    title: "Products",
  },
  [routes.kitchens]: {
    title: "Kitchens",
    description: "Our current range",
    image: "/media/jackson/_hero-01.jpg",
  },
  [routes.handles]: {
    title: "Handles",
    description: "Find the perfect match",
    image: "/media/assets/parts-handles-hero.jpg",
  },
  [routes.cabinets]: {
    title: "Cabinets",
    description: "To compliment your kitchen",
    image: "/media/assets/parts-cabinets-hero.jpg",
  },
  [routes.worktops]: {
    title: "Composite worktops",
    description: "Our composite and stone ranges",
    image: "/media/assets/parts-worktops-hero.jpg",
  },
  [routes.laminates]: {
    title: "Laminate worktops",
    description: "Standard and slimline",
    image: "/media/assets/parts-laminates-hero.jpg",
  },
  [routes.appliances]: {
    title: "Appliances",
    description: "From leading partner brands",
    image: "/media/assets/appliances-header.jpg",
  },
  [routes.sinks]: {
    title: "Sinks",
    description: "To suit every space",
    image: "/media/assets/parts-sinks-hero.jpg",
  },
  [routes.taps]: {
    title: "Taps",
    description: "From classic to modern",
    image: "/media/assets/parts-taps-hero.jpg",
  },
  [routes.shelving]: {
    title: "Shelving",
    description: "Essential storage",
    image: "/media/assets/parts-shelving-hero.jpg",
  },
  [routes.splashbacks]: {
    title: "Splashbacks",
    description: "A variety of colours",
    image: "/media/assets/parts-splashbacks-hero.jpg",
  },
  [routes.about]: {
    title: "About",
  },
  [routes.history]: {
    title: "Our history",
    description: "Over a century of experience",
    image: "/media/assets/history-header.jpg",
  },
  [routes.innovation]: {
    title: "Innovation and premises",
    description: "World-class UK manufacturing",
    image: "/media/assets/innovation-and-premises-header.jpg",
  },
  [routes.mission]: {
    title: "Mission and values",
    description: "What sets us apart",
    image: "/media/assets/mission-and-values-header.jpg",
  },
  [routes.sustainability]: {
    title: "Sustainability",
    description: "At the heart of all we do",
    image: "/media/assets/sustainability-header.jpg",
  },
  [routes.sustainabilityBook]: {
    title: "The little book of sustainability",
    image: "/media/assets/sustainability-book.jpg",
  },
  [routes.accreditations]: {
    title: "Accreditations",
    description: "Our market-leading service",
    image: "/media/assets/accreditations-header.jpg",
  },
  [routes.corporateResponsibility]: {
    title: "Committed to excellence",
    description: "Right the first time",
    image: "/media/assets/excellence-header.jpg",
  },
  [routes.marketSectors]: {
    title: "Market Sectors",
  },
  [routes.privateDevelopers]: {
    title: "House Builders",
    description: "All the major UK house builders",
    image: "/media/assets/private-developers-header.jpg",
  },
  [routes.socialHousing]: {
    title: "Social Housing Providers",
    description: "Affordable housing and supported living",
    image: "/media/assets/social-housing-header.jpg",
  },
  [routes.bespokeSolutions]: {
    title: "High Rise Developers",
    description: "Truly unique projects that set the bar",
    image: "/media/assets/bespoke-solutions-header.jpg",
  },
  [routes.retailCustomers]: {
    title: "Retail customers",
    description: "Visit our showroom on the South Coast",
    image: "/media/assets/retail-customers-header.jpg",
  },
  [routes.news]: {
    title: "News",
  },
  [routes.newsLatestTrends]: {
    title: "Latest trends",
    description: "What’s new and what’s next",
    image: "/media/assets/news-cat-latest-trends.jpg",
  },
  [routes.newsCaseStudies]: {
    title: "Case studies",
    description: "Recently completed projects",
    image: "/media/assets/news-cat-case-studies.jpg",
  },
  [routes.newsKitchenLife]: {
    title: "Kitchen life",
    description: "Getting the most out of your PRMF kitchen",
    image: "/media/assets/news-cat-kitchen-life.jpg",
  },
  [routes.newsSustainability]: {
    title: "Sustainability in focus",
    description: "Our commitments",
    image: "/media/assets/news-cat-sustainability.jpg",
  },
  [routes.newsCompany]: {
    title: "Company news",
    description: "What‘s happening at PRMF",
    image: "/media/assets/news-cat-company.jpg",
  },
  [routes.workingWithUs]: {
    title: "Working with us",
    description: "What it’s like being part of our team",
    image: "/media/assets/working-with-us-header.jpg",
  },
  [routes.careers]: {
    title: "Available positions",
    description: "Current available position",
    image: "/media/assets/careers-slideshow-02.jpg",
  },
  [routes.ourTeam]: {
    title: "Meet the team",
    description: "The people who make us who we are",
    image: "/media/assets/team-header.jpg",
  },
  [routes.visualiser]: {
    title: "Kitchen visualiser",
    image: "/media/assets/kitchen-visualizer-hero.jpg",
  },
  [routes.brochures]: {
    title: "Brochures",
    image: "/media/assets/brochures-header.jpg",
  },
  [routes.extraParts]: {
    title: "Buying direct",
    image: "/media/assets/extra-parts-header.jpg",
  },
  [routes.terms]: {
    title: "Terms and conditions",
    image: "/media/assets/contact-header.jpg",
  },
  [routes.contact]: {
    title: "Contact us",
    image: "/media/assets/contact-header.jpg",
  },
  [routes.contactAftersales]: {
    title: "Buying direct",
  },
  [routes.contactCustomerCare]: {
    title: "Customer experience",
  },
  [routes.customerReviews]: {
    title: "Customer Reviews",
    image: "/media/assets/customer-reviews-header.jpg",
  },
  private: {
    title: "House Builder",
  },
  social: {
    title: "Social Housing Provider",
  },
  bespoke: {
    title: "High Rise Developer",
  },
  retail: {
    title: "Retail customer",
  },
};

export function t(
  route: string,
  key: "title" | "description" | "image",
  fallback = true,
) {
  return dict[route]?.[key] || (fallback ? `${route}_${key}` : undefined);
}
